import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  useTheme
} from '@material-ui/core';
import ToBeDoneIcon from '@material-ui/icons/RadioButtonUnchecked';
import SubmittedIcon from '@material-ui/icons/History';
import ApprovedIcon from '@material-ui/icons/ThumbUp';
import UploadedIcon from '@material-ui/icons/Publish';
import Waiting from 'src/components/Waiting';

function TrafficByDevice(props) {
  const [page] = useState(1);
  const [data, setData] = useState({});
  const [devices, setDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://staging.dashboard.theboxofficenetwork.com/api/stats?function=approvals', {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setData(
          {
            datasets: [
              {
                data: [response.rows[0].tobedone, response.rows[0].submitted, response.rows[0].approved, response.rows[0].uploaded],
                backgroundColor: [
                  colors.indigo[500],
                  colors.green[500],
                  colors.red[600],
                  colors.orange[600]
                ],
                borderWidth: 8,
                borderColor: colors.common.white,
                hoverBorderColor: colors.common.white
              }
            ],
            labels: ['To Be Done', 'Awaiting Approval', 'Approved', 'Final Cut']
          }
        );

        setDevices(
          [
            {
              title: 'To Be Done',
              value: response.rows[0].tobedone,
              icon: ToBeDoneIcon,
              color: colors.indigo[500]
            },
            {
              title: 'Awaiting Approval',
              value: response.rows[0].submitted,
              icon: SubmittedIcon,
              color: colors.green[500]
            },
            {
              title: 'Approved',
              value: response.rows[0].approved,
              icon: ApprovedIcon,
              color: colors.red[600]
            },
            {
              title: 'Final Cut',
              value: response.rows[0].uploaded,
              icon: UploadedIcon,
              color: colors.orange[600]
            }
          ]
        );

        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [page]);

  const theme = useTheme();

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  if (isLoading) {
    return (<Waiting />);
  }
  return (
    <Card {...props}>
      <CardHeader title="Film Approval Process" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: 'relative'
          }}
        >
          <Doughnut
            data={data}
            options={options}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            pt: 2
          }}
        >
          {devices.map(({
            color,
            icon: Icon,
            title,
            value
          }) => (
            <Box
              key={title}
              sx={{
                p: 1,
                textAlign: 'center'
              }}
            >
              <Icon color="action" />
              <Typography
                color="textPrimary"
                variant="body1"
              >
                {title}
              </Typography>
              <Typography
                style={{ color }}
                variant="h2"
              >
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

export default TrafficByDevice;
