const LoginCheck = () => {
  const tokenValue = localStorage.getItem('token');
  if (tokenValue !== undefined && tokenValue !== '') {
    return (
      <span />
    );
  }
  window.location = '/home';
  return <span />;
};
export default LoginCheck;
