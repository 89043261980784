import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  LinearProgress,
  Typography
} from '@material-ui/core';
import { orange, red } from '@material-ui/core/colors';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';
import DataUsage from '@material-ui/icons/DataUsage';
import LoginCheck from 'src/components/LoginCheck';
import Waiting from 'src/components/Waiting';

const TasksProgress = (props) => {
  const [page] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [aryData, setData] = useState([]);

  useEffect(() => {
    fetch('https://staging.dashboard.theboxofficenetwork.com/api/stats?function=translations', {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [page]);

  if (isLoading) {
    return (<Waiting />);
  }

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <LoginCheck />
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              TRANSLATION PROGRESS
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {((aryData.rows[0].translated * 100) / (aryData.rows[0].ready)).toFixed(2)}
              %
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: orange[600],
                height: 56,
                width: 56
              }}
            >
              <InsertChartIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box sx={{ pt: 3 }}>
          <LinearProgress value={75.5} variant="determinate" />
        </Box>

        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <DataUsage sx={{ color: red[900] }} />
          <Typography
            sx={{
              color: red[900],
              mr: 1
            }}
            variant="body2"
          >
            &nbsp;
            {aryData.rows[0].translated}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Translated
          </Typography>
        </Box>

        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <DataUsage sx={{ color: red[900] }} />
          <Typography
            sx={{
              color: red[900],
              mr: 1
            }}
            variant="body2"
          >
            &nbsp;
            {aryData.rows[0].ready}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Films Edited
          </Typography>
        </Box>

      </CardContent>
    </Card>
  );
};
export default TasksProgress;
