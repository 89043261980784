import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import { yellow } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import Waiting from 'src/components/Waiting';

function Ripped(props) {
  const [page] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [aryData, setData] = useState([]);
  useEffect(() => {
    fetch('https://staging.dashboard.theboxofficenetwork.com/api/stats?function=ripped', {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [page]);

  if (isLoading) return <Waiting />;

  return (
    <Card {...props} sx={{ height: '100%' }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              FILMS RIPPED
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {aryData.rows[0].total}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: yellow[600],
                height: 56,
                width: 56
              }}
            >
              <PeopleIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
        >
          <ArrowUpwardIcon sx={{ color: yellow[900] }} />
          <Typography
            variant="body2"
            sx={{
              color: yellow[900],
              mr: 1
            }}
          >
            {aryData.rows[0].rip4k}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            4K
          </Typography>
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
        >
          <ArrowUpwardIcon sx={{ color: yellow[900] }} />
          <Typography
            variant="body2"
            sx={{
              color: yellow[900],
              mr: 1
            }}
          >
            {aryData.rows[0].rip1080p}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            1080p
          </Typography>
        </Box>

        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            pt: 2
          }}
        >
          <ArrowUpwardIcon sx={{ color: yellow[900] }} />
          <Typography
            variant="body2"
            sx={{
              color: yellow[900],
              mr: 1
            }}
          >
            {aryData.rows[0].ripother}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Other
          </Typography>
        </Box>

      </CardContent>
    </Card>
  );
}

export default Ripped;
