/* eslint-disable no-alert */
/* eslint-disable react/jsx-wrap-multilines */
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  TextField
} from '@material-ui/core';

const serialize = require('form-serialize');

const qualities = [
  {
    value: '4K',
    label: '4K'
  },
  {
    value: '1080P',
    label: '1080p'
  },
  {
    value: 'No',
    label: 'No'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

const FilmDetails = ({ films, ...rest }) => {
  const [values, setValues] = useState({
    dbz_id: films.rows[0].dbz_id,
    editor: films.rows[0].editor,
    film_title: films.rows[0].film_title,
    final_cut_actual: films.rows[0].final_cut_actual,
    final_cut_target: films.rows[0].final_cut_target,
    movie_id: films.rows[0].movie_id,
    priority: films.rows[0].priority,
    producer: films.rows[0].producer,
    raw_delivery_actual: films.rows[0].raw_delivery_actual,
    raw_delivery_target: films.rows[0].raw_delivery_target,
    rip_quality: films.rows[0].rip_quality,
    ripped: films.rows[0].ripped,
    rough_playlist: films.rows[0].rough_playlist,
    sourcing_complete: films.rows[0].sourcing_complete,
    studio_approved: films.rows[0].studio_approved,
    studio_id: films.rows[0].studio_id,
    subtitle_languages: films.rows[0].subtitle_languages,
    thumbnail_approved: films.rows[0].thumbnail_approved,
    titles_complete: films.rows[0].titles_complete,
    translations_complete: films.rows[0].translations_complete,
    upload_youtube: films.rows[0].upload_youtube,
    upload_facebook: films.rows[0].upload_facebook,
    voice_languages: films.rows[0].voice_languages,
  });

  const handleDelete = () => {
    const url = 'https://staging.dashboard.theboxofficenetwork.com/api/films?function=delete&film_id=' + films.rows[0].film_id;
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then(() => {
        document.location = '/app/films';
      })
      .catch((error) => console.log(error));
  };
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };
  const handleChecked = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.checked
    });
  };

  /**
   ** handleSubmit
   */
  const handleSubmit = (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = serialize(form, { empty: true, hash: false });
    console.log('Saving data ' + formData);
    const url = 'https://staging.dashboard.theboxofficenetwork.com/api/films?function=update&film_id=' + films.rows[0].film_id;
    console.log('to URL:' + url);
    fetch(url, {
      method: 'POST',
      body: formData,
      headers: new Headers({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    })
      .then((res) => res.json())
      .then(() => {
        console.log('Updated');
      })
      .catch((error) => console.log(error));
  };

  return (
    <form {...rest} noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Card>
        <CardHeader subheader="Workflow Progression" title={values.film_title} titleTypographyProps={{ fontSize: '24px' }} />
        <Divider />
        <CardContent>
          <Grid container spacing={6} wrap="wrap">
            <Grid
              item
              md={4}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              xs={12}
            >
              <Typography color="textPrimary" gutterBottom variant="h6">
                Sourcing
              </Typography>
              <FormControlLabel
                label="Ripped"
                control={
                  <Checkbox
                    color="primary"
                    name="ripped"
                    value="true"
                    checked={values.ripped}
                    onChange={handleChecked}
                  />
                }
              />

              <FormControlLabel
                label="Sourcing Complete"
                control={
                  <Checkbox
                    color="primary"
                    name="sourcing_complete"
                    value="true"
                    checked={values.sourcing_complete}
                    onChange={handleChecked}
                  />
                }
              />

              <FormControlLabel
                label="Titles Complete"
                control={
                  <Checkbox
                    color="primary"
                    name="titles_complete"
                    value="true"
                    checked={values.titles_complete}
                    onChange={handleChecked}
                  />
                }
              />

              <FormControlLabel
                label="Translations Complete"
                control={
                  <Checkbox
                    color="primary"
                    name="translations_complete"
                    value="true"
                    checked={values.translations_complete}
                    onChange={handleChecked}
                  />
                }
              />
            </Grid>

            <Grid
              item
              md={4}
              sm={6}
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
              xs={12}
            >
              <Typography color="textPrimary" gutterBottom variant="h6">
                Approvals
              </Typography>

              <FormControlLabel
                label="Rough Cuts Approved"
                control={
                  <Checkbox
                    color="primary"
                    name="studio_approved"
                    value="true"
                    checked={values.studio_approved}
                    onChange={handleChecked}
                  />
                }
              />

              <FormControlLabel
                label="Thumbnail Done"
                control={
                  <Checkbox
                    color="primary"
                    name="thumbnail_approved"
                    value="true"
                    checked={values.thumbnail_approved}
                    onChange={handleChecked}
                  />
                }
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
      </Card>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      />
      <Card>
        <CardHeader subheader="Edit and Save Below" title="Film Data" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Film Title"
                name="film_title"
                required
                onChange={handleChange}
                value={values.film_title}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="CinemaSource movie_id"
                name="movie_id"
                onChange={handleChange}
                value={values.movie_id || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="DBZ Id"
                name="dbz_id"
                onChange={handleChange}
                value={values.dbz_id || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Priority"
                name="priority"
                onChange={handleChange}
                value={values.priority || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Target Raw Date"
                name="raw_delivery_target"
                onChange={handleChange}
                value={values.raw_delivery_target || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Actual Raw Date"
                name="raw_delivery_actual"
                onChange={handleChange}
                value={values.raw_delivery_actual || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                label="Rough Cut Playlist"
                name="rough_playlist"
                onChange={handleChange}
                value={values.rough_playlist || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Clip Selector"
                name="editor"
                onChange={handleChange}
                value={values.editor || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Select Rip Quality"
                name="rip_quality"
                onChange={handleChange}
                select
                SelectProps={{ native: true }}
                value={values.rip_quality || ''}
                variant="outlined"
              >
                {qualities.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Final Cut Target Date"
                name="final_cut_target"
                onChange={handleChange}
                value={values.final_cut_target || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Final Cut Production Date"
                name="final_cut_actual"
                onChange={handleChange}
                value={values.final_cut_actual || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Voice Languages"
                name="voice_languages"
                onChange={handleChange}
                value={values.voice_languages || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Subtitle Languages"
                name="subtitle_languages"
                onChange={handleChange}
                value={values.subtitle_languages || ''}
                variant="outlined"
              />
            </Grid>
            <Divider />
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Upload YouTube Date"
                name="upload_youtube"
                onChange={handleChange}
                value={values.upload_youtube || ''}
                variant="outlined"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Upload Facebook Date"
                name="upload_facebook"
                onChange={handleChange}
                value={values.upload_facebook || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Producer/Final Cut Editor"
                name="producer"
                onChange={handleChange}
                value={values.producer || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => window.confirm('Are you sure?') && handleDelete()}
          >
            Delete
          </Button>
          <Button
            sx={{ mx: 1 }}
            color="primary"
            variant="contained"
            type="submit"
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default FilmDetails;
