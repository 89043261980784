/* eslint-disable no-alert */
/* eslint-disable prefer-destructuring */
import { Helmet } from 'react-helmet';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Grid,
  Container,
  Link,
  Typography
} from '@material-ui/core';
import ClipCard from 'src/components/clip/ClipCard';
import Waiting from 'src/components/Waiting';

/*
** Clips - show all the clips for one film_id
*/
const Clips = () => {
  console.log('Clip Card');
  const { id } = useParams();
  const [aryClips, setClips] = useState([]); // No data yet
  const [isLoading, setIsLoading] = useState(true); // Start showing wheel

  useEffect(() => {
    // Base URL
    let url = 'https://staging.dashboard.theboxofficenetwork.com/api/clips?function=search';

    // Filter by film_id
    if (id !== '' && id !== 'undefined' && id !== undefined) {
      url += '&film_id=' + id;
    }

    // Get the data
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setClips(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);

  if (isLoading) return (<Waiting />);
  if (aryClips.rows === null) return (<Typography variant="h1">No Clips Found</Typography>);
  return (
    <>
      <Helmet>
        <title>Clips | The Boxoffice Network</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Typography variant="h1">{aryClips.rows[0].film_title}</Typography>
          <Box sx={{ pt: 3 }}>
            <Grid
              container
              spacing={3}
            >
              {aryClips.rows.map((clipRecord) => (
                <Grid
                  item
                  key={clipRecord.clip_id}
                  lg={4}
                  md={6}
                  xs={12}
                >
                  <ClipCard clip={clipRecord} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Clips;
