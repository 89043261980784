const Logo = (props) => (
  <img
    alt="Logo"
    height="52px"
    width="52px"
    src="/static/images/logo300x300.png"
    {...props}
  />
);

export default Logo;
