import React, { useState, useEffect } from 'react';
import LoginCheck from 'src/components/LoginCheck';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Waiting from 'src/components/Waiting';

const LatestClips = (props) => {
  const [page] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [aryData, setData] = useState([]);

  useEffect(() => {
    fetch('https://staging.dashboard.theboxofficenetwork.com/api/stats?function=clips', {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [page]);

  if (isLoading) return <Waiting />;
  return (
    <Card {...props}>
      <LoginCheck />
      <CardHeader
        subtitle={`${aryData.rows.length} in total`}
        title="Latest Rough Cuts Produced"
      />
      <Divider />
      <List>
        {aryData.rows.map((product, i) => (
          <ListItem
            divider={i < aryData.rows.length - 1}
            key={product.film_id}
          >
            <ListItemAvatar>
              <img
                alt={product.film_title}
                src={product.poster}
                style={{
                  height: 60,
                  width: 40
                }}
              />
            </ListItemAvatar>
            <ListItemText
              primary={product.film_title}
              secondary={`Number of clips ${product.count}`}
            />
            <IconButton
              edge="end"
              size="small"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          <a href="/app/products">View all</a>
        </Button>
      </Box>
    </Card>
  );
};
export default LatestClips;
