import CircularProgress from '@material-ui/core/CircularProgress';

const Waiting = (props) => (
  <div style={{ flex: 1, paddingTop: 120, paddingLeft: 120 }} {...props}>
    <span className="glyphicon-left glyphicon glyphicon-refresh spinning">
      <CircularProgress />
    </span>
  </div>
);

export default Waiting;
