import PropTypes from 'prop-types';
import {
  Button,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GetAppIcon from '@material-ui/icons/GetApp';
import products from 'src/__mocks__/products';

const titles = (clip) => {
  const ret = 'test';
  return (
    <div>
      <Typography align="left" color="textPrimary" gutterBottom variant="subtitle2">{clip.title}</Typography>
      <Typography align="left" color="textPrimary" gutterBottom variant="caption">{clip.description}</Typography>
    </div>
  );
};

const ProductCard = ({ product, ...rest }) => (
  <Card
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
    }}
    {...rest}
  >
    <CardContent>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'left',
          pb: 3
        }}
      >
        <img
          height="150"
          width="100"
          alt="Poster"
          src={product.poster}
          variant="rounded"
        />
        <Box
          sx={{
            display: 'block',
            justifyContent: 'left',
            pl: 10
          }}
        >
          {product.clips.map((clip) => (<Typography align="left" color="textPrimary" gutterBottom variant="subtitle2">{clip.title}</Typography>))}
        </Box>
      </Box>

      <Typography align="left" color="textPrimary" gutterBottom variant="h4">
        {product.film_title}
      </Typography>
      <Typography align="center" color="textPrimary" variant="body1">
        {product.title}
      </Typography>
    </CardContent>
    <Box sx={{ flexGrow: 1 }} />
    <Divider />
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2} sx={{ justifyContent: 'space-between' }}>
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <AccessTimeIcon color="action" />
          <Typography
            color="textSecondary"
            display="inline"
            sx={{ pl: 1 }}
            variant="body2"
          >
            {product.description}
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Button
            variant="link"
            color="action"
            startIcon={<GetAppIcon />}
            href={'/app/clips/' + product.film_id}
          />
          <Typography
            color="textSecondary"
            display="inline"
            sx={{ pl: 0 }}
            variant="body2"
          >
            {product.clipcount}
            &nbsp;Clips
          </Typography>
        </Grid>
      </Grid>
    </Box>
  </Card>
);

ProductCard.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductCard;
