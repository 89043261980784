/* eslint-disable no-alert */
/* eslint-disable prefer-destructuring */
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Link,
  Typography
} from '@material-ui/core';

/*
** setAuth - keep login token in state
*/
const setAuth = (doFunction, newToken, newEmail, newImage, newName) => {
  const date = new Date();
  const json = JSON.stringify(date);

  if (doFunction === 'login') {
    // Must be a valid login
    if (newEmail.indexOf('webedia') === -1) {
      alert('Must login with a webedia email address');
      return;
    }
  }

  if (doFunction === 'logout') {
    localStorage.setItem('email', '');
    localStorage.setItem('token', '');
    localStorage.setItem('date', '');
    localStorage.setItem('image', '');
    localStorage.setItem('name', '');
    return;
  }

  localStorage.setItem('email', newEmail);
  localStorage.setItem('token', newToken);
  localStorage.setItem('date', json);
  localStorage.setItem('image', newImage);
  localStorage.setItem('name', newName);

  // Go home!
  window.location = '/';
};

const Logout = () => {
  console.log('Logging out');
  setAuth('logout', '', '', '', '');

  return (
    <>
      <Helmet>
        <title>Login | The Boxoffice Network</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <img alt="The Boxoffice Network" src="/static/images/bxnlogo.png" />
          <Typography color="textPrimary" variant="h2">
            You are logged out.
          </Typography>
          <Typography color="primary" variant="h3">
            <Link href="/home">
              Click here to log back in again.
            </Link>
          </Typography>
        </Container>
      </Box>
    </>
  );
};

export default Logout;
