import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import FilmDetails from 'src/components/film/FilmDetails';
import Waiting from 'src/components/Waiting';

function SettingsView() {
  const [page] = useState(1);
  const [aryFilms, setFilms] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    fetch('https://staging.dashboard.theboxofficenetwork.com/api/films?function=search&id=' + id, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setFilms(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [page]);

  if (isLoading) return <Waiting />;

  return (
    <>
      <Helmet>
        <title>Film Details | The Boxoffice Network</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth="lg">
          <FilmDetails films={aryFilms} />
        </Container>
      </Box>
    </>
  );
}

export default SettingsView;
