import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  useTheme,
  colors
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Waiting from 'src/components/Waiting';

const Launches = (props) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});
  const [page] = useState(1);
  let dates = [];
  let actuals = [];
  let plans = [];
  const { ltype } = props;

  useEffect(() => {
    let url = 'https://staging.dashboard.theboxofficenetwork.com/api/stats?function=rawcutsperweek';
    if (ltype !== 'raw') {
      url = 'https://staging.dashboard.theboxofficenetwork.com/api/stats?function=finalcutsperweek';
    }
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        dates = response.rows.map((row) => row.weekdate);
        plans = response.rows.map((row) => row.planned);
        actuals = response.rows.map((row) => row.actual);
        setData({
          datasets: [
            {
              backgroundColor: colors.indigo[500],
              data: actuals,
              label: 'Actual'
            },
            {
              backgroundColor: colors.grey[200],
              data: plans,
              label: 'Planned'
            }
          ],
          labels: dates
        });
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [page]);

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          barThickness: 12,
          maxBarThickness: 10,
          barPercentage: 0.5,
          categoryPercentage: 0.5,
          ticks: {
            fontColor: theme.palette.text.secondary
          },
          gridLines: {
            display: false,
            drawBorder: false
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider
          }
        }
      ]
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: 'index',
      titleFontColor: theme.palette.text.primary
    }
  };

  if (isLoading) return <Waiting />;
  return (
    <Card {...props}>
      <CardHeader
        action={(
          <Button endIcon={<ArrowDropDownIcon />} size="small" variant="text">
            Week Ending
          </Button>
        )}
        title={ltype === 'raw' ? 'CADENCE: Films Submitted Per Week' : 'CADENCE: Films Published Per Week'}
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: 'relative'
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          p: 2
        }}
      >
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          Overview
        </Button>
      </Box>
    </Card>
  );
};

export default Launches;
