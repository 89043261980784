import { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Waiting from 'src/components/Waiting';
import Typography from '@material-ui/core/Typography';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';

const FilmTitles = ({ MovieId, handleParentClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(true);
  const [aryData, setData] = useState([]);

  /* Style */
  const styles = (theme) => ({
    dialogTitle: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    dialogContent: {
      padding: 0,
    },
  });

  useEffect(() => {
    const url = 'https://staging.dashboard.theboxofficenetwork.com/api/foreign?function=search&movie_id=' + MovieId;
    console.log(url);
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
        if (MovieId > 0) setIsOpen(true);
      })
      .catch((error) => console.log(error));
  }, [MovieId]);

  const handleClose = () => {
    setIsOpen(false);
    handleParentClose();
  };

  if (isLoading) return <Waiting />;

  if (aryData.rows === null) return (<span />);

  return (
    <Dialog
      fullScreen={false}
      fullWidth
      maxWidth="md"
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle className={styles.dialogTitle} id="responsive-dialog-title">
        <h2>{aryData.rows[0].name}</h2>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <TableContainer sx={{ height: 900 }}>
          <Table>
            {
              aryData.rows.map(
                (foreign) => (
                  <TableRow>
                    <TableCell>
                      <Typography variant="h6">{foreign.country}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">{foreign.lang}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h6">{foreign.title}</Typography>
                    </TableCell>
                  </TableRow>
                )
              )
            }
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default FilmTitles;
