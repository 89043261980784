import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/DataUsage';
import MoneyIcon from '@material-ui/icons/Money';
import { red } from '@material-ui/core/colors';
import Waiting from 'src/components/Waiting';

function MoviesDelivered(props) {
  const [page] = useState(1);
  const [aryData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://staging.dashboard.theboxofficenetwork.com/api/stats?function=delivered', {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json'
      })
    })
      .then((res) => res.json())
      .then((response) => {
        setData(response);
        setIsLoading(false);
      })
      .catch((error) => console.log(error));
  }, [page]);

  if (isLoading) {
    return (<Waiting />);
  }

  return (
    <Card sx={{ height: '100%' }} {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              FILMS TO BE DELIVERED
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {aryData.rows[0].total}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: red[600],
                height: 56,
                width: 56
              }}
            >
              <MoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ArrowDownwardIcon sx={{ color: red[900] }} />
          <Typography
            sx={{
              color: red[900],
              mr: 1
            }}
            variant="body2"
          >
            &nbsp;
            {aryData.rows[0].delivered}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Published
          </Typography>
        </Box>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ArrowDownwardIcon sx={{ color: red[900] }} />
          <Typography
            sx={{
              color: red[900],
              mr: 1
            }}
            variant="body2"
          >
            &nbsp;
            {aryData.rows[0].sourced}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Sourced
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

export default MoviesDelivered;
