/* eslint-disable no-alert */
/* eslint-disable prefer-destructuring */
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography
} from '@material-ui/core';
import GoogleIcon from 'src/icons/Google';

import { GoogleLogin } from 'react-google-login';

/*
** setAuth - keep login token in state
*/
const setAuth = (doFunction, newToken, newEmail, newImage, newName) => {
  const date = new Date();
  const json = JSON.stringify(date);
  // let emailAddress = localStorage.getItem('email');
  // let tokenValue = localStorage.getItem('token');

  if (doFunction === 'login') {
    // Must be a valid login
    if (newEmail.indexOf('webedia') === -1) {
      alert('Must login with a webedia email address');
      return;
    }
  }

  if (doFunction === 'logout') {
    localStorage.setItem('email', '');
    localStorage.setItem('token', '');
    localStorage.setItem('date', '');
    localStorage.setItem('image', '');
    localStorage.setItem('name', '');
    return;
  }

  localStorage.setItem('email', newEmail);
  localStorage.setItem('token', newToken);
  localStorage.setItem('date', json);
  localStorage.setItem('image', newImage);
  localStorage.setItem('name', newName);

  // Go home!
  window.location = '/';
};

const responseGoogle = (googleUser) => {
  const idToken = googleUser.getAuthResponse().id_token;
  const profile = googleUser.getBasicProfile();
  console.log(googleUser);
  console.log({ accessToken: idToken });

  // anything else you want to do (save to localStorage)...
  console.log('ID: ' + profile.getId()); // Don't send this directly to your server!
  console.log('Full Name: ' + profile.getName());
  console.log('Given Name: ' + profile.getGivenName());
  console.log('Family Name: ' + profile.getFamilyName());
  console.log('Image URL: ' + profile.getImageUrl());
  console.log('Email: ' + profile.getEmail());
  setAuth('login', idToken, profile.getEmail(), profile.getImageUrl(), profile.getName());
};

const Login = () => {
  console.log('Logging in');

  return (
    <>
      <Helmet>
        <title>Login | The Boxoffice Network</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <img alt="The Boxoffice Network" src="/static/images/bxnlogo.png" />
          <form>
            <Box sx={{ mb: 3 }}>
              <Typography color="textPrimary" variant="h2">
                Sign in
              </Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Login with your webedia-group.com Google account
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <GoogleLogin
                  clientId="623265186947-07qe3titj76g764p4c41hd7e51m921sa.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <Button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      fullWidth
                      startIcon={<GoogleIcon />}
                      size="large"
                      variant="contained"
                    >
                      Login with Google
                    </Button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy="single_host_origin"
                />
              </Grid>
            </Grid>
            <Typography color="textSecondary" variant="body1">
              Don&apos;t have an account?&nbsp;&nbsp;
              <Link component={RouterLink} to="/register" variant="h6">
                Sign up
              </Link>
            </Typography>
          </form>
        </Container>
      </Box>
    </>
  );
};

export default Login;
